.login-form {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .login-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 64px;
    border-radius: 20px;
    background: #e0e0e0;
    box-shadow:  20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
    h1 {
      text-align: center;
    }
  }

  .loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
