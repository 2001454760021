.company-detail-page {
  .company-detail-header {
    display: flex;
    justify-content: space-between;
  }
  .company-detail-card {
    width: 100%;
    .ant-card-body {
      display: flex;
      gap: 20px;
    }

    .info-box {
      flex-direction: column;
      .ant-card-body {
        flex-direction: column;
      }
    }
  }
}
