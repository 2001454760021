body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  /* Colors */
  --white: #fff;
  --yellow-200: #e5ab00;
  --yellow-300: #fdc00e;
  --yellow-500: #ffefc3;
  --yellow-800: #fedf87;
  --light-500: #c4c4c4;
  --grey-050: #f0f0f0;
  --grey-300: #bdbdbd;
  --grey-400: #9e9e9e;
  --grey-600: #767676;
  --grey-800: #212121;
  --blue-050: #A2ADBB;
  --blue-100: #f5f6f7;
  --blue-200: #0E1F33;
  --blue-500: #9AA4B1;
  --blue-900: #354a63;

  --green-100: #459B68;
  --green-500: #4caf50;
  --red-100: #D84315;
  --red-500: #f44336;
  --red-600: #D84315;

  --toastify-color-progress-light: #fdc00e;

  /* Font Sizes */
  --body-text-size-lg: 1rem;
  --body-text-size-md: 0.87rem;
  --body-text-size-sm: 0.756rem;
}