#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.ant-layout-sider {
  height: 100vh;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 5px;
  background-color: #455A64;
  margin: 10px;
  border-radius: 6px;
  &-text {
    color: #FAFAFA;
    font-size: 20px;
  }
}

.header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.logout-button {
  color: var(--blue-900);
  cursor: pointer;
  margin-right: 20px;
}